import api from '../../ApiConfig';
import { Apis } from '../../config';

const getAboutUs = async () => {
    try {
        let result = await api.get(Apis.GetAbout);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 
const getSeo = async (page_name) => {
    try {
        let result = await api.get(Apis.GetSeo+page_name);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 



const exportedObject = {
    getAboutUs,
    getSeo
};
export default exportedObject;