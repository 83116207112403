const schemaOne = () => {
    let data = {
           "@context": "http://schema.org",
      "@type": "Organization",
      "name": "Destiny Calling",
      "url": "https://destinycalling.in/",
      "logo": "/images/logo.png",
      "sameAs": [
        "https://twitter.com/destinycalling",
        "https://www.facebook.com/destinycalling.in",
        "https://www.instagram.com/destinycalling.in",
        "https://www.linkedin.com/in/destiny-calling-466147159"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 7045825899",
        "contactType": "customer service"
      },
      "description": "Unlock your potential with Destiny Calling - your guide to personal and professional growth."

    };
    return JSON.stringify(data);
};
const schemaTwo = () => {
  let data = {
    "@context": "http://schema.org",
    "@type": "ItemList",
    "url": "https://destinycalling.in/our-program",
    "name": "Destiny Calling Programs",
    "description": "Explore a variety of empowering programs for personal and professional development.",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@type": "Product",
          "name": "France Internship Program",
          "description": "Embark on a transformative journey with our internship program in France."
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@type": "Product",
          "name": "Mauritius Internship Program",
          "description": "Discover exclusive internship opportunities in the tropical paradise of Mauritius."
        }
      },
      {
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@type": "Product",
          "name": "Netherlands Work Permit Program",
          "description": "Secure your work permit and explore professional opportunities in the Netherlands."
        }
       },
      {
        "@type": "ListItem",
        "position": 4,
        "item": {
          "@type": "Product",
          "name": "Italy Work Permit Program",
          "description": "Secure your work permit and explore professional opportunities in the Italy."
        }
       },
      {
        "@type": "ListItem",
        "position": 5,
        "item": {
          "@type": "Product",
          "name": "Gulf internship job placement program",
          "description": "Secure your work and job permit and explore professional opportunities in the gulf country."
        }
       },
     {
        "@type": "ListItem",
        "position": 6,
        "item": {
          "@type": "Product",
          "name": "USA",
          "description": "Secure your work and job permit and explore professional opportunities in the USA country."
        }
      }
    ]

  };
  return JSON.stringify(data);
};
const exportedObject = {
    schemaOne,
    schemaTwo
};
export default exportedObject;