
// const API_URL = "http://localhost:4000";
const API_URL = "https://destinyapi.fluentzea.com";
const Apis = {
  GetAllPost:`${API_URL}/api/posts`,
  GetSinglePost:`${API_URL}/api/post/`,
  GetLatestPosts:`${API_URL}/api/posts/latest`,
  GetAllFaq:`${API_URL}/api/faq/all`,
  GetLatestFaq:`${API_URL}/api/faq/latest`,
  SearchFaq:`${API_URL}/api/faq/search?q=`,
  GetAllTestimonial:`${API_URL}/api/testimonial/all`,
  GetLatestTestimonial:`${API_URL}/api/testimonial/latest`,
  GetAllVideoTestimonial:`${API_URL}/api/video/testimonial/all`,
  GetTeamMember:`${API_URL}/api/team/all`,
  SendEnquiry:`${API_URL}/api/enquiry-submit`,
  SendContactEnquiry:`${API_URL}/api/contact-submit`,
  GetFooterWidget:`${API_URL}/api/footer/widget`,
  GetHomeBannerWidget:`${API_URL}/api/home/banner`,
  GetAbout:`${API_URL}/api/about/content`,
  GetSeo:`${API_URL}/api/seo/`,
  UploadCV:`${API_URL}/api/upload-cv`,
  GetAppointmentSlots:`${API_URL}/api/get-slots/`,
  CreateAppointment:`${API_URL}/api/enquiry-submit`,
  GetFreeSlots:`${API_URL}/api/get-entries/`,
  GetBrochure:`${API_URL}/api/brochure-submit`,
  GetProgramPage:`${API_URL}/api/program/page`,
  GetPrograms:`${API_URL}/api/programs`,
  GetSingleProgram:`${API_URL}/api/programs/`,
  GetWhyChoose:`${API_URL}/api/why-choose/page`,
  GetWhyChoosePages:`${API_URL}/api/why-choose-us`,
  GetWhyChooseSinglePage:`${API_URL}/api/why-choose-us/`,
  GetHomeProgram:`${API_URL}/api/program/home`,

  
};
export { API_URL, Apis };
