import React, { Fragment, useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import { GetPrograms } from "../../helpers";
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet-async';
const OurProgram = () => {
  const [programs, setPrograms] = useState([]);
  const [programPage, setProgramPage] = useState('');

   const getPrograms = async()=>{
    const resp = await GetPrograms.getAllPrograms();
    if(resp && resp.status){
        setPrograms(resp.programs);
    }
   }
   const getProgramPage = async() =>{
    const resp = await GetPrograms.getProgramPage();
    if(resp && resp.status){
      setProgramPage(resp.programPageContent);
    }
  
   }
   useEffect(()=>{
    getProgramPage();
    getPrograms();
   },[])

  return (
    <Fragment>
              {
                programPage !== '' &&   <Helmet>
                <meta charSet="utf-8" />
                <title>{programPage.meta_title}</title>
                <meta name="description" content={programPage.meta_description}/>
                <meta name="keywords" content={programPage.meta_keywords}/>
                <link rel="canonical" href={programPage.page_url} />
                 {/* og details */}
                 <meta property="og:title" content={programPage.og_title}/>
                <meta property="og:description" content={programPage.og_description}/>
                <meta property="og:url" content={'http://destinycalling.in/'}/>
                <meta content="website" property="og:type"/>
                <meta content={programPage.og_image} property="og:image" />
                <meta content={programPage.og_img_alt} property="og:image:alt"/>
                <meta content="Destiny Calling" property="og:site_name"/>
                {/* twitter details */}
                <meta name="twitter:card" content={programPage.twitter_card}/>
                <meta name="twitter:site" content={programPage.twitter_site}/>
                <meta name="twitter:title" content={programPage.twitter_title}/>
                <meta name="twitter:description" content={programPage.twitter_description}/>
                <meta name="twitter:image" content={programPage.twitter_image}/>
            </Helmet>
            }
      <div className="innerPageWrapper">
      {
        programPage !== '' && <>
        <div className="innerPageBanner-2">
          <img
            src={programPage.main_banner}
            alt="about-banner"
            className="img-fluid"
          />
        </div>
        <div className="page-content pt-50 ">
          <div className="container">
            <div className="our_program_sec_1">
             {parse(programPage.section_one)}
             
            </div>
            <div className="inner-page-section">
             
                {
                    programs.length !== 0 && programs.map((item, i)=>{
                        if(item.is_right_thumb){
                            return <div className="row align-center" key={item.id}> 
                           
                          <div className="col-md-7">
                            <div className="inner-content-box">
                               {parse(item.page_sort_content)}
                              <Link to={`/our-program/${item.slug}`} className="primaryButton">Learn more</Link>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="inner-img-box">
                              <img src={item.thumbnail} alt="img" />
                            </div>
                          </div>
                        </div>
                        }
                        return <div className="row align-center" key={item.id}> 
                        <div className="col-md-5">
                        <div className="inner-img-box">
                          <img src={item.thumbnail} alt="img" />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="inner-content-box">
                           {parse(item.page_sort_content)}
                          <Link to={`/our-program/${item.slug}`} className="primaryButton">Learn more</Link>
                        </div>
                      </div>
                    </div>
                    }) 
                }
               

            </div>
            <div className="inner-page-section">
            {parse(programPage.section_two)}
            </div>
          </div>
          <div className="faq-action-box">
            <div className="container text-center">
            {parse(programPage.section_three)}
            </div>
          </div>
          {/* <div className="program-form">
            <div className="container">
              <h5>
                BOOK A FREE DISCOVERY CALL WITH OUR INTERNSHIP ADVISOR TODAY
              </h5>
              <div className="program-form-wrapper">
                <form>
                  <div className="row form-group">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="XYZ"
                        id="name"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="918888888888"
                        id="phone"
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="xyz2233@gmail.com"
                        id="email"
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button className="primaryButton" type="submit">
                      Book Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
        </div>
        </>
      }
      
      </div>
    </Fragment>
  );
};

export default OurProgram;
