import React,{useState, useEffect} from "react";
const SlotItem = ({slotItem,onChangeSlot, selectedDate, isCurrentDate, keyU}) => {
  const [isShow, setShow] = useState(false);


  function compare_time(start_time, end_time, ){
    start_time = start_time.split(" ");
    var time = start_time[0].split(":");
    var stime = time[0];
    if(start_time[1] === "PM" && stime<12) stime = parseInt(stime) + 12;
    start_time = stime + ":" + time[1] + ":00";

    end_time = end_time.split(" ");
    var time1 = end_time[0].split(":");
    var etime = time1[0];
    if(end_time[1] === "PM" && etime<12) etime = parseInt(etime) + 12;
    end_time = etime + ":" + time1[1] + ":00";

    if (start_time !== '' && end_time !== '') { 
   
        if (end_time >= start_time) {
          setShow(true);
        }
    }
}



useEffect(()=>{
  const bookingStartTiming = slotItem.slot.substring(0,8).toUpperCase();
  const bookingEndTiming = slotItem.endTime.toUpperCase();
  compare_time(bookingStartTiming, bookingEndTiming);
},[slotItem])



  return (
    <>
      {
        isCurrentDate ? 
        isShow ? <div key={slotItem} className="slotItems"> 
        <label>
          <input
            type="radio"
            value={slotItem.slot}
            name="booking_slot"
            onChange={onChangeSlot}
          />
          {slotItem.slot} 
        </label>
      </div> : null : <div key={slotItem} className="slotItems">
        <label>
          <input
            type="radio"
            value={slotItem.slot}
            name="booking_slot"
            onChange={onChangeSlot}
          />
          {slotItem.slot} 
        </label>
      </div>
      }
    </>
    
  );
};

export default SlotItem;
