import React, { Fragment, useState, useEffect, forwardRef } from "react";
import "../assets/css/appointment.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiCalendar } from "react-icons/fi";
import { GetAppointment, GetPages } from "../helpers";
import { Formik } from 'formik';
import { format } from 'date-fns'
import { BsCheck2Circle } from "react-icons/bs";
import { Link } from "react-router-dom";
import SlotItem from "../components/partials/SlotItem";
import AdvisorList from "../components/appointments/AdvisorList";
import { Helmet } from 'react-helmet-async';
//const bookingSlots = ['11:00 am to 11:15 am', '11:30 am to 11:45 am', '12:00 am to 12:15 pm','06:00 pm to 06:15 pm','06:30 pm to 06:45 pm','07:00 pm to 07:15 pm'];

const AppointmentBooking = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const [selectSlot, setSelectSlot] = useState('');
  const [step, setStep] = useState(0);
  const [completeData, setCompleteData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [avlSlots, setSlots] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  const [sltDate, setSlt] = useState(new Date());
  const [isCurrentDate, setCurrentDate] = useState(false);
  const [seoDetail, setSeoDetail] = useState('');

  const getFreeSlot = async(selectedDate)=>{
    let resp = await GetAppointment.getFreeSlots(selectedDate);
    if(resp && resp.status){
      setSlots(resp.freeSlots);
      setCurrentDate(resp.isCurrentDate)
    }
  }

  const getSeo = async ()=>{
    const resp = await GetPages.getSeo('book_appointment')
    if(resp && resp.status){
      setSeoDetail(resp.seoData)
    } 
   }



  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="dateSection">
      <button className="dateBtn" onClick={onClick} ref={ref}>
        <FiCalendar fontSize={20} /> 
        Pick a Date
      </button>
      <div className="pickedDate"><FiCalendar fontSize={20} /> <span>{value}</span></div>
    </div>
  ));

  const handleChangeDate = (date) => {
    setStartDate(date);
    // setSelectedDate(format(date, 'dd-MM-yyyy'));
    setSelectedDate(format(date, 'yyyy-MM-dd'));
    getFreeSlot(format(date, 'yyyy-MM-dd'));
    setSlt(date)
  };

  const onChangeSlot = (e)=>{
    setSelectSlot(e.target.value);
    let getSingleSlot = avlSlots.find(item=>item.slot === e.target.value);
    setAdvisors(getSingleSlot.advisors);
  }

  useEffect(() => {
    setSelectedDate(format(startDate, 'yyyy-MM-dd'));
    if(startDate.getDay() !== 0 && startDate.getDay() !== 6){
      getFreeSlot(format(startDate, 'yyyy-MM-dd'));
    }
    getSeo();

  }, [startDate]);

  const handleSteps = () => {
    if (selectSlot !== "") {
      setStep(1);
    }
  };

  const handleSubmit = async(values, { setSubmitting, resetForm  })=>{
    setLoading(true)
       values.date = selectedDate;
       values.slot = selectSlot;
     
   let resp = await GetAppointment.createAppointments(values);
     
       if(resp && resp.status){
        setLoading(false);
        setCompleteData(resp.booking_details);
        setStep(2);
       }
     
  }
  var dt = new Date();
  var h =  dt.getHours(), m = dt.getMinutes();
  var curTime = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');

  return (
    <Fragment>
      {
                seoDetail && seoDetail !== '' &&   <Helmet>
                <meta charSet="utf-8" />
                <title>{seoDetail.meta_title}</title>
                <meta name="description" content={seoDetail.meta_description}/>
                <meta name="keywords" content={seoDetail.meta_keywords}/>
                <link rel="canonical" href={seoDetail.page_url} />

                 {/* og details */}
                 <meta property="og:title" content={seoDetail.og_title}/>
                <meta property="og:description" content={seoDetail.og_description}/>
                <meta property="og:url" content={'http://destinycalling.in/'}/>
                <meta content="website" property="og:type"/>
                <meta content={seoDetail.og_image} property="og:image" />
                <meta content={seoDetail.og_img_alt} property="og:image:alt"/>
                <meta content="Destiny Calling" property="og:site_name"/>
                {/* twitter details */}
                <meta name="twitter:card" content={seoDetail.twitter_card}/>
                <meta name="twitter:site" content={seoDetail.twitter_site}/>
                <meta name="twitter:title" content={seoDetail.twitter_title}/>
                <meta name="twitter:description" content={seoDetail.twitter_description}/>
                <meta name="twitter:image" content={seoDetail.twitter_image}/>
            </Helmet>
            }
      <div className="booking-wrapper">
        <div className="booking-title">
        <h1>Book Appointment</h1>
        <h5>BOOK A FREE DISCOVERY CALL WITH OUR INTERNSHIP ADVISOR TODAY</h5>
        </div>
      
        <div className="booking-box">
          <div className="form-progress">
            <ul className="form-progress-steps">
              <li className={`${step === 0 || step === 1 || step === 2 ? 'active' : ''}`}>
                <div className="checkIcon">
                  <img src="/images/check-mark.svg" alt="check"/>
                </div>
                <span>Select Slot</span>
              </li>
              <li className={`${step === 1 || step === 2 ? 'active' : ''}`}>
                <div className="checkIcon">
                  <img src="/images/check-mark.svg" alt="check"/>
                </div>
                <span>User Details</span>
              </li>
              <li className={`${step === 2 ? 'active' : ''}`}>
                <div className="checkIcon">
                  <img src="/images/check-mark.svg" alt="check"/>
                </div>
                <span>Summary</span>
              </li>
            </ul>
          </div>
          <div className="form-box">
            {step === 0 && (
              <div className="form-box-step-1">
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={(date) => handleChangeDate(date)}
                  customInput={<ExampleCustomInput />}
                  minDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                  filterDate={date => {
                    // Disable weekends (Saturday and Sunday)
                    return date.getDay() !== 0 && date.getDay() !== 6;
                  }}
                />
                <div className="slot-box">
                  <div className="slot-title">
                    <h3>Available Slots </h3> 
                  </div>
                  <div className="slotList">
                  {avlSlots.length === 0 ? <div className="warning-box"> <p>Slot available today</p> </div>:
                   avlSlots.map((slotItem, i)=>{
                     if(slotItem.advisors && slotItem.advisors.length === 0) return null;
                    return <SlotItem key={slotItem.id} slotItem={slotItem} onChangeSlot={onChangeSlot} curTime={curTime} isCurrentDate={isCurrentDate}/>
                   })
                  }
                  </div>
                </div>
                
                 { avlSlots.length !== 0 && <div className="actionBtn">
                  <button onClick={handleSteps} disabled={ selectSlot === '' } className={`${
                              selectSlot === '' ? "disableBtn" : ""
                            }`}>Next</button>
                </div> }
                
              
              </div>
            )}
            {step === 1 && (
              <div className="form-box-step-2">
                <div className="form-box-2">
                  <Formik
                    initialValues={{ name: "", email: "", phone: "", advisor:"", interested_internship_destination:"" }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.name) {
                        errors.name = "Name is required";
                      }
                      if (!values.advisor) {
                        errors.advisor = "Select your advisor name";
                      }
                      if (!values.interested_internship_destination) {
                        errors.interested_internship_destination = "Internship destination is required";
                      } 
                      if (!values.email) {
                        errors.email = "Email is required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }

                      if (!values.phone) {
                        errors.phone = "Phone is required";
                      } else if (!/^[0-9]{10}$/i.test(values.phone)) {
                        errors.phone = "Invalid phone number";
                      }
                     
                      return errors;
                    }}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            className="form-control br-0"
                            id="name"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                           {errors.name && touched.name && <span className='error-help'>{errors.name}</span>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            className="form-control br-0"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                           {errors.email && touched.email && <span className='error-help'>{errors.email}</span>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone">Phone</label>
                          <input
                            type="text"
                            className="form-control br-0"
                            id="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                          />
                           {errors.phone && touched.phone && <span className='error-help'>{errors.phone}</span>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="advisor">Advisor</label>
                           <AdvisorList handleChange={handleChange} handleBlur={handleBlur} advisor={values.advisor} advisors={advisors}/>
                           {errors.advisor && touched.advisor && <span className='error-help'>{errors.advisor}</span>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="interested_internship_destination">Interested internship destination</label>
                          <select className="form-control br-0" name="interested_internship_destination" onChange={handleChange} onBlur={handleBlur} value={values.interested_internship_destination}>
                             <option value={''}>---Select---</option>
                             <option value={'France'}>France</option>
                             <option value={'Mauritius'}>Mauritius</option>
                             <option value={'Netherlands'}>Netherlands</option>
                             <option value={'The USA'}>The USA</option>
                             <option value={'Italy'}>Italy</option>
                             <option value={'Gulf'}>Gulf</option>
                          </select>
                           {errors.interested_internship_destination && touched.interested_internship_destination && <span className='error-help'>{errors.interested_internship_destination}</span>}
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor="comments">Comment</label>
                          <textarea
                            className="form-control br-0"
                            id="comments"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.comments}
                          ></textarea>
                        </div>
                        <div className="actionBtn">
                          <button
                            type="submit"
                            disabled={Object.keys(errors).length}
                            className={`${
                              Object.keys(errors).length ? "disableBtn" : ""
                            }`}
                            onClick={handleSteps}
                          >
                            {isLoading ? 'Please wait..' : 'Submit Details'}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
              {step === 2 && (
              <div className="form-box-step-3">
                <div className="form-box-2">

                  <div className="bookingFinal">
                    <h4><BsCheck2Circle fontSize={30} color="#fff"/> <span>Booking submitted successfully</span> </h4>
                  </div>
                
      <div className="card" style={{marginTop:30}}>
        <div className="card-header">
          Booking Details
        </div>
        <div className="card-body">
        {
                  completeData !== null &&  <div className="overview-box">
                    <table>
                        <tr>
                            <th>Name</th><td>{completeData.name}</td>
                        </tr>
                        <tr>
                            <th>Email</th><td>{completeData.email}</td>
                        </tr>
                        <tr>
                            <th>Phone</th><td>{completeData.phone}</td>
                        </tr>
                        <tr>
                            <th>Comment</th><td>{completeData.comments}</td>
                        </tr>
                        <tr>
                            <th>Advisor Name</th><td>{completeData.advisor}</td>
                        </tr>
                        <tr>
                            <th>Interested internship destination</th><td>{completeData.interested_internship_destination}</td>
                        </tr>
                        
                        <tr>
                            <th>Date</th><td>{completeData.date}</td>
                        </tr>
                        <tr>
                            <th>Slot</th><td>{completeData.slot}</td>
                        </tr>
                    </table>
              </div>
                 }

                 <div className="backtohome">
                     <Link to={'/'}>Back to home</Link>
                 </div>
        </div>
      </div>
              
                </div>
                
                 
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppointmentBooking;
