
import React, {useEffect, useState} from 'react';
import './assets/css/App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import Header from './components/partials/Header';
import Footer from './components/partials/Footer';
//pages
import Home from './pages/Home';
import About from './pages/About';
import Faq from './pages/Faqs/Faq';
import OurServices from './pages/OurServices';
import ContactUs from './pages/ContactUs';
import Blog from './pages/Blog';
import Single from './pages/Blog/Single';
import Testimonial from './pages/Testimonial';
import OurProgram from './pages/OurProgram/OurProgram'
import SingleProgram from './pages/OurProgram/SingleProgram'
import FrenchLanguage from './pages/FrenchLanguage';
import WildfitHealthProgram from './pages/WildfitHealthProgram';
import WhyChooseUs from './pages/WhyChooseUs/WhyChooseUs';
import SingleWhyChooseUs from './pages/WhyChooseUs/SingleWhyChooseUs';
import AppointmentBooking from './pages/AppointmentBooking';
import PageNotFound from './pages/PageNotFound';
import ScrollToTop from './components/ScrollToTop';
import { GetWidgets } from './helpers';

function App() {
  const [isLoading, setLoading] = useState(true);
  const [footerWidgets, setFooterWidgets] = useState(null);
   const getFooterWidgets = async () =>{
         let resp = await GetWidgets.getFooterWidgets();
         if(resp && resp.status){
          setFooterWidgets(resp.footer_widgets);
          setLoading(false); 
         }
   }
  useEffect(() => {
    setTimeout(function () {
      getFooterWidgets();
    }, 1000);
  }, []);


  if (isLoading) {
    return (
      <div className="fakeLoader" ><div className="lod-1"><div className="lod-2"></div></div></div>
    );
  }


  return (
    <BrowserRouter>
    <HelmetProvider>
      <Header/>
      <ScrollToTop/>
      <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/about' element={<About/>}></Route>
          <Route path='/faq' element={<Faq/>}></Route>
          <Route path='/our-services' element={<OurServices/>}></Route>
          <Route path='/contact-us' element={<ContactUs/>}></Route>
          <Route path='/blog' element={<Blog/>}></Route>
          <Route path='/blog/:slug' element={<Single/>}></Route>
          <Route path='/testimonial' element={<Testimonial/>}></Route>
          <Route path='/our-program' element={<OurProgram/>}></Route>
          <Route path='/our-program/:slug' element={<SingleProgram/>}></Route>
          <Route path='/french-language' element={<FrenchLanguage/>}></Route>
          <Route path='/wildfit-health-program' element={<WildfitHealthProgram/>}></Route>
          <Route path='/book-appointment' element={<AppointmentBooking/>}></Route>
          <Route path='/why-choose-us' element={<WhyChooseUs/>}></Route>
          <Route path='/why-choose-us/:slug' element={<SingleWhyChooseUs/>}></Route>
          <Route path="/404" element={<PageNotFound/>} />
          <Route path="*" element={<PageNotFound/>} />
      </Routes> 
      <Footer footerWidgets={footerWidgets}/>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
