import React, { Fragment, useEffect, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { motion } from "framer-motion"
import SwipeSlider from '../components/SwipeSlider';
import Accordions from '../components/Accordions';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GetFaqs, GetTestimonial, GetPages, GetWidgets, GetPosts, Schema, GetPrograms } from '../helpers';
import { format, parseISO } from 'date-fns'

//home components
import MainBanner from '../components/homeComponents/MainBanner';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import portfolios from '../data/portfolios.json'

function Home() {
    // const [isLoading, setLoading] = useState(true);
    const [faqs, setFaqs] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [latestPosts, setLatestPosts] = useState([]);
    const [aboutContent, setAboutContent] = useState(null);
    const [homeBanner, setHomeBanner] = useState(null);
    const [seoDetail, setSeoDetail] = useState('');
    const [programs, setPrograms] = useState([]);


    const getData = async()=>{
        try {
        let [res1, res2, res3, res4, res5, res6, res7] = await Promise.all([
            GetFaqs.getLatestFaqs(),
            GetTestimonial.getLatestTestimonial(),
            GetPages.getAboutUs(),
            GetWidgets.getHomeBannerWidgets(),
            GetPosts.getLatestPost(),
            GetPages.getSeo('home'),
            GetPrograms.getHomeProgram()
          ]);
          if(res1 && res1.status){
            setFaqs(res1.faqs)
          }
    
          if(res2 && res2.status){
            setTestimonials(res2.testimonials)
           }

           if(res3 && res3.status){
            setAboutContent(res3.aboutContent)
           }
           if(res4 && res4.status){
            setHomeBanner(res4.homeBanner)
           }
           if(res5 && res5.status){
            setLatestPosts(res5.posts)
           }
           if(res6 && res6.status){
            setSeoDetail(res6.seoData)
           }
           if(res7 && res7.status){
            setPrograms(res7.programs)
           }
          
        }
        catch(err) {
          console.log(err);
        };
    }

    useEffect(()=>{
         getData();
    },[])


    return (
        <Fragment>
            {
                seoDetail && seoDetail !== '' &&   <Helmet>
                <meta charSet="utf-8" />
                <title>{seoDetail.meta_title}</title>
                <meta name="description" content={seoDetail.meta_description}/>
                <meta name="keywords" content={seoDetail.meta_keywords}/>
                <link rel="canonical" href={seoDetail.page_url} />
                <meta content="Destiny Calling - Your Path to Personal and Professional Growth" property="og:title" />
                <meta content="Explore empowering programs, internships, and resources for holistic development. Join us on a journey to unlock your potential and redefine success." property="og:description"/>
                <meta content="https://destinycalling.in/" property="og:url"/>

                <meta property="og:title" content={seoDetail.og_title}/>
                <meta property="og:description" content={seoDetail.og_description}/>
                <meta property="og:url" content={'http://destinycalling.in/'}/>
                <meta content="website" property="og:type"/>
                <meta content={seoDetail.og_image} property="og:image" />
                <meta content={seoDetail.og_img_alt} property="og:image:alt"/>
                <meta content="Destiny Calling" property="og:site_name"/>

                <meta name="twitter:card" content={seoDetail.twitter_card}/>
                <meta name="twitter:site" content={seoDetail.twitter_site}/>
                <meta name="twitter:title" content={seoDetail.twitter_title}/>
                <meta name="twitter:description" content={seoDetail.twitter_description}/>
                <meta name="twitter:image" content={seoDetail.twitter_image}/>
                <script className="structured-data-list" type="application/ld+json">
                   {Schema.schemaOne()}
                </script>
                <script className="structured-data-list" type="application/ld+json">
                   {Schema.schemaTwo()}
                </script>
                
            </Helmet>
            }
         
            <MainBanner homeBanner={homeBanner} />
            <div className='designElement'>
                    <div className='designEle'>
                        <svg width="478" height="188" viewBox="0 0 478 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-6 4C18.9796 37.3181 95.6736 105.586 202.613 112.114C336.288 120.274 398.061 103.444 473 183" stroke="url(#paint0_linear_58_2348)" strokeOpacity="0.8" strokeWidth="13" />
                            <defs>
                                <linearGradient id="paint0_linear_58_2348" x1="-6" y1="4" x2="388.021" y2="341.623" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#286989" />
                                    <stop offset="0.547917" stopColor="#34647a" />
                                    <stop offset="1" stopColor="#88acbc" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            <div className='ourLanguages'>
                <div className='container'>
                    <h1>BUILD AN INTERNATIONAL CAREER, ONE INTERNSHIP AT A TIME</h1>
                       <div className='row'>
                    {
                        programs.map((item)=>{
                            return <div className='col-lg-6 mmb-15' key={item.home_title}>
                            <div className='programText'>
                                <div className='program-text-header'>
                                <img src={item.home_icon} alt='program'/>
                                <h3>{item.home_title}</h3>
                                </div>
                               {parse(item.home_description)}
                                <Link className="borderButton style-2" to={`/our-program/${item.slug}`}>Learn more</Link>
                            </div>
                        </div>
                        })
                    }
                    </div>
                </div>
            </div>
          
            {
                aboutContent !== null &&   <div className='aboutSection'>
          
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-5 pfc'>
                            <motion.img
                                whileHover={{ scale: 1.1, }}
                                whileTap={{ scale: 1 }}
                                initial={{ scale: 1, transitionDelay: 2, }}
                                src={aboutContent.home_page_image} alt='about' className='img-fluid' />
                        </div>
                        <div className='col-md-7'>
                            <div className='aboutContent'>
                                <h1>{aboutContent.page_title}</h1>
                                {parse(aboutContent.home_page_content)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            
          
            <div className='ourPortfolio'>
                <div className='container'>
                    <h1>Our portfolio of services include :</h1>
                    <div className='portfolioIcons '>
                        {
                            portfolios.map((item)=>{
                              return  <div className={`portfolioIconBox ${item.class}`} key={item.id}>
                              <div className='iconWrapper'>
                                  <img src={item.icon_img} alt="" />
                              </div>
                              <h5>{item.title}</h5>
                              <p>{item.description}</p>
                          </div>
  
                            })
                        }
                    </div>
                </div>
            </div>
            
            <div className='testimonial'>
                <div className='container'>
                    <h1> READ TRANSFORMATIVE STORIES OF <br/> GROWTH AND ACHIEVEMENT</h1>
                 
                    {
                        testimonials.length !== 0 &&  <SwipeSlider testimonials={testimonials}/>
                    }
                   
                    <div className='ViewMoreBox'>
                    <Link to="/testimonial" className='primaryButton'>View More</Link>
                    </div>
                   
                </div>
                
            </div>
            <div className='pioneersSection'>
                <div className='container'>
                    <div className='pioneersSectionInner'>
                    <div className='row align-center'>
                        <div className='col-md-6 text-center'>
                           <img src='/images/globe.png' alt='globe'/>
                        </div>
                        <div className='col-md-6'>
                            <div className='pioneersSectionContent'>
                                <h1>Your Destiny's Premier Partner <br/> <span style={{color:'#373737'}}>We promise you shall rate us #1</span></h1>
                                <p><span>1200</span><br/> Dreams Fulfilled </p>
                                <p><span>11+ Years </span><br/> Of Experience </p>
                                <p><span>Over 200 </span><br/> Michelin Star & Gastronomic Restaurant Partners </p>
                                
                            </div>
                       </div>
                    </div>
                    </div>
                </div>
            </div>

            {
                latestPosts.length !== 0 && <div className='latestPost'>
                <div className='container'>
                    <h1>‘Internship Insights to French Gastronomy : <br/>Our Blogs have got you covered’</h1>
                    <p className='text-center'>Explore the dynamic world of internships through our captivating blogs, where we unveil the latest trends and possibilities in the industry. Whether you seek answers, motivation, or reasoning to shape your career, our engaging blogs have it all. Dive into the world of cultural exposure and gain valuable insights to empower your professional journey. Learn, Grow, and Stay updated with Destiny Calling.</p>
                    <div className='row'>
                      {
                        latestPosts.map((item, i)=>{
                          return <div className='col-lg-4' key={item.id}>
                            
                          <div className='latestPostItems'>
                              <div className='latestPostItemsThumb'>
                                  <img src={item.thumbnail} alt='li.jpg' />
                              </div>
                              <div className='latestPostItemsContent'>
                                  <h3>{item.title}</h3>
                                  <div className='post_author'>
                                      <p>{item.auther_name}</p>
                                      <p>{ format (parseISO(item.post_date), 'd MMM Y') }</p>
                                  </div>               
                                  <div style={{marginTop:'15px'}}>
                                    <Link to={`blog/${item.slug}`} className='borderButton'>View More</Link>
                                  </div>                  
                                  

                              </div>
                          </div>
                      </div>
                        })
                      }
                      
                    </div>
                    {
                        latestPosts.length >= 6  &&  <div className='ViewMoreBox mt-25'>
                        <Link to="/blog" className='primaryButton'>Discover More</Link>
                        </div>
                    }
                   
                   
                </div>
            </div>
            }
              <div className='career-section' style={{
                 backgroundImage:`url('/images/blogThumb.jpg')`
              }}>
                <div className='container'>
                    <div className='career-inner'>
                      <h3>Looking to work in a creative space that offers growth and a learning curve?</h3>
                      <Link to="/contact-us" className='borderButton'>Join Our Team</Link>
                    </div>
                </div>
                <div className='careerOverlay'></div>
                
            </div>
             <div className='faq'>
                <div className='container'>
                    <h1>Any Questions you could possibly have, we have tried to answer it all here.</h1>
                    <Accordions data={faqs} />
                    <div className='ViewMoreBox1'>
                    <Link to="/faq" className='primaryButton'>View More</Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Home