import React, { Fragment, useEffect, useState } from "react";
import { GetWhyChooseUse } from "../../helpers";
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
const SingleWhyChooseUs = () => {
    const [data, setData] = useState('');
    const {slug} = useParams();
       const getPageData = async (pageSlug)=>{
        const resp = await GetWhyChooseUse.getSingleWhyChoosePages(pageSlug)
        if(resp && resp.status){
            setData(resp.data);
        } 
       }
       useEffect(()=>{
        getPageData(slug);
       },[slug])
    return (
        <Fragment>
             {
                data !== '' &&   <Helmet>
                <meta charSet="utf-8" />
                <title>{data.meta_title}</title>
                <meta name="description" content={data.meta_description}/>
                <meta name="keywords" content={data.meta_keywords}/>
                <link rel="canonical" href={data.page_url} />
                 {/* og details */}
                 <meta property="og:title" content={data.og_title}/>
                <meta property="og:description" content={data.og_description}/>
                <meta property="og:url" content={'http://destinycalling.in/'}/>
                <meta content="website" property="og:type"/>
                <meta content={data.og_image} property="og:image" />
                <meta content={data.og_img_alt} property="og:image:alt"/>
                <meta content="Destiny Calling" property="og:site_name"/>
                {/* twitter details */}
                <meta name="twitter:card" content={data.twitter_card}/>
                <meta name="twitter:site" content={data.twitter_site}/>
                <meta name="twitter:title" content={data.twitter_title}/>
                <meta name="twitter:description" content={data.twitter_description}/>
                <meta name="twitter:image" content={data.twitter_image}/>
            </Helmet>
            }
            <div className="innerPageWrapper">
                {
                    data !== '' && <>
                     <div className="innerPageBanner-2">
                    <img
                        src={data.banner_image}
                        alt="banner"
                        className="img-fluid"
                    />
                </div>
                <div className="page-content pt-15">
                    <section className="inner-program-sec-1">
                        <div className="container">
                           {parse(data.section_one)}

                        </div>
                    </section>
                    <section className="inner-program-sec-3 pt-0">
                        <div className="container">

                            <h4 className="lang-title text-center mb-25">{data.section_two_title}</h4>
                            <div className='services'>
                                <div className='container'>
                                    <div className='row justify-content-center'>
                                        {
                                            data.benefits.length > 0 && data.benefits.map((item)=>{
                                                return <div className='col-lg-4 mb-25' key={item.id}>
                                                <div className='service-items item2'>
                                                    <img src={item.icon_image} alt={item.icon_image}/>
                                                    <h4>{item.title}</h4>
                                                    {parse(item.description)}
                                                </div>
                                            </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className=" quote-box">
                        <div className="inner-quote-box">
                            <p className="quote-text">"FitInsideOut has been a game-changer for our students! The program is a holistic guide to lifestyle and has transformed their overall well-being. I've noticed a significant improvement in their energy levels and concentration, allowing them to perform at their best.”</p>
                            <div className="quote-user">
                                <span> - Rashi Gulati</span>
                                <span>Business Development Head, Destiny Calling</span>
                            </div>
                        </div>
                    </section>

                </div>
                    </>
                }
               

            </div>
        </Fragment>
    );
};

export default SingleWhyChooseUs;
