import api from '../../ApiConfig';
import { Apis } from '../../config';



const getWhyChooseContent = async () => {
    try {
        let result = await api.get(Apis.GetWhyChoose);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 
const getWhyChoosePages = async () => {
    try {
        let result = await api.get(Apis.GetWhyChoosePages);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 
const getSingleWhyChoosePages = async (slug) => {
    try {
        let result = await api.get(Apis.GetWhyChooseSinglePage+slug);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const exportedObject = {
    getWhyChooseContent,
    getWhyChoosePages,
    getSingleWhyChoosePages
};
export default exportedObject;