import React from 'react'

const AdvisorList = ({advisors, handleChange, handleBlur, advisor}) => {
  return (
    <select  id="advisor" onChange={handleChange} onBlur={handleBlur} value={advisor}   className="form-control br-0">
    <option value={''}>---Select---</option>
    {
      advisors && advisors.length > 0 && advisors.map((item)=>{
        return  <option value={item} key={item}>{item}</option>
      })
    }
</select>
  )
}

export default AdvisorList