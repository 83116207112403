import React, {useState} from "react";
import Modal from './Modal'
import  {Formik}  from "formik";
import { MdOutlineClose } from "react-icons/md";
import { GetEnquires } from "../../helpers";
import swal from 'sweetalert';

const Brochures = ({ isOpen, handleClose, brochureUrl }) => {
  const [isLoading, setLoading] = useState(false);
  const handleSubmit = async(values, { setSubmitting, resetForm  })=>{
    const data = {
      name:values.name,
      email:values.email,
      phone:values.phone,
      institution:values.institution,
      query_message:values.query_message,
      brochure_name:brochureUrl ? brochureUrl : ''
    }
    let resp = await GetEnquires.brochureSubmit(data);
    if(resp && resp.status){
      // if(brochureUrl){
      //   downloadPDF(brochureUrl, 'brochures.pdf');
      //   // const link = document.createElement('a');
      //   // link.href = brochureUrl;
      //   // link.setAttribute('download', brochureUrl);
      //   // link.setAttribute('target', '_blank');
      //   // document.body.appendChild(link);
      //   // link.click();
       
      // }

      swal({
        title: "Good job!",
        text: resp.message,
        icon: "success",
        button: "OK",
      });
    }else{
      swal({
        title: "Sorry!",
        text: resp.message,
        icon: "error",
        button: "OK",
      });
    }
    setLoading(false);
    setSubmitting(false);
    resetForm();
    handleClose();
  }
  // const downloadPDF = (brochureUrl, filename) => {

  //   fetch(`${brochureUrl}`)
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       response.blob();

  //     })
  //     // .then(blob => {
  //     //   const url = window.URL.createObjectURL(blob);
  //     //   const link = document.createElement('a');
  //     //   link.href = url;
  //     //   link.setAttribute('download', filename);
  //     //   document.body.appendChild(link);
  //     //   link.click();
  //     //   document.body.removeChild(link);
  //     // })
  //     .catch(error => console.error('Error downloading PDF:', error));
  // };

  return (
    <div>     <Modal isOpen={isOpen}>
      <div className="modalHeader">
     
        <h3>Get our brochures and a quote </h3>
        <button onClick={handleClose}>
          <MdOutlineClose size={25} />
        </button>
      </div>
      <div className="modalBody">
        <Formik
          initialValues={{ name: "", email: "", phone: "", institution: "", query_message: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Name is required";
            }
         

            if (!values.email) {
              errors.email = "Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                values.email
              )
            ) {
              errors.email = "Invalid email address";
            }

            if (!values.phone) {
              errors.phone = "Phone is required";
            } else if (!/^[0-9]{10}$/i.test(values.phone)) {
              errors.phone = "Invalid phone number";
            }
            if (!values.institution) {
              errors.institution = "Institution is required";
            }
            if (!values.query_message) {
              errors.query_message = "Query message is required";
            }

            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <div className="col-md-6">
                  <label>Name</label>
                  <input type="text" className="form-control" name="name" onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name} />
                     {errors.name && touched.name && <span className='error-help'>{errors.name}</span>}
                </div>
                <div className="col-md-6">
                  <label>Email</label>
                  <input type="text" className="form-control" name="email" onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email} />
                     {errors.email && touched.email && <span className='error-help'>{errors.email}</span>}
                </div>

              </div>

              <div className="form-group row">
                <div className="col-md-6">
                  <label>Phone </label>
                  <input type="text" className="form-control" name="phone" onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone} />
                     {errors.phone && touched.phone && <span className='error-help'>{errors.phone}</span>}
                </div>
                <div className="col-md-6">
                  <label>Institution </label>
                  <input type="text" className="form-control" name="institution" onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.institution} />
                     {errors.institution && touched.institution && <span className='error-help'>{errors.institution}</span>}
                </div>
              </div>

              <div className="form-group">
                <label>Query Message</label>
                <textarea className="form-control" name="query_message" onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.query_message}></textarea>
                   {errors.query_message && touched.query_message && <span className='error-help'>{errors.query_message}</span>}
              </div>
              <button className='sbtContact' type="submit" disabled={isSubmitting}>
                                                            {
                                                                isLoading ? 'Please wait..' : 'Download Now'
                                                            }
                                                            
                                                            </button>
            </form>
          )}
        </Formik>
      </div>


    </Modal></div>
  )
}

export default Brochures