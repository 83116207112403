import api from '../../ApiConfig';
import { Apis } from '../../config';



const getProgramPage = async () => {
    try {
        let result = await api.get(Apis.GetProgramPage);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const getAllPrograms = async () => {
    try {
        let result = await api.get(Apis.GetPrograms);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const getSingleProgram = async ($slug) => {
    try {
        let result = await api.get(Apis.GetSingleProgram + $slug);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

// const getLatestPost = async () => {
//     try {
//         let result = await api.get(Apis.GetLatestPosts);
//         return result.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }; 

const getHomeProgram = async () => {
    try {
        let result = await api.get(Apis.GetHomeProgram);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const exportedObject = {
    getProgramPage,
    getAllPrograms,
    getSingleProgram,
    getHomeProgram
};
export default exportedObject;