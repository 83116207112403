import React from 'react'


const BrandLogos = ({title}) => {
  return (
    <div className='brand-section'>
 <div className='container'>
           <h4>{title}</h4>
           <div className='brand-inner'>
           <div className='row justify-content-center'>
                <div className='col-md-3 col-6'>
                  <div className='logo-items'>
                         <img src='/images/logos/logo1.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-3 col-6'>
                  <div className='logo-items'>
                  <img src='/images/logos/logo2.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-3 col-6'>
                  <div className='logo-items'>
                  <img src='/images/logos/logo3.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-3 col-6'>
                  <div className='logo-items'>
                  <img src='/images/logos/logo4.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-3 col-6'>
                  <div className='logo-items'>
                  <img src='/images/logos/logo5.png' alt='logo'/>
                     </div>
                </div>
          
                <div className='col-md-3 col-6'>
                  <div className='logo-items'>
                  <img src='/images/logos/logo7.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-3 col-6'>
                  <div className='logo-items'>
                  <img src='/images/logos/logo8.png' alt='logo'/>
                     </div>
                </div>
                
                
           </div>
           </div>
         
           
    </div>
    </div>
   
  )
}

export default BrandLogos