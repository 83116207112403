import api from '../../ApiConfig';
import { Apis } from '../../config';

const sendEnquiry = async (data) => {
    try {
        let result = await api.post(Apis.SendEnquiry, data);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const sendContactEnquiry = async (data) => {
    try {
        let result = await api.post(Apis.SendContactEnquiry, data);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 


const brochureSubmit = async (data) => {
    try {
        let result = await api.post(Apis.GetBrochure, data);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const exportedObject = {
    sendEnquiry,
    sendContactEnquiry,
    brochureSubmit
};
export default exportedObject;