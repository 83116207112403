import api from '../../ApiConfig';
import { Apis } from '../../config';

const getAppointmentSlots = async (date) => {
    try {
        let result = await api.get(Apis.GetAppointmentSlots + date);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 
const createAppointments = async (data) => {
    try {
        let result = await api.post(Apis.CreateAppointment, data);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 
const getFreeSlots = async (date) => {
    try {
        let result = await api.get(Apis.GetFreeSlots+date);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 


const exportedObject = {
    getAppointmentSlots,
    createAppointments,
    getFreeSlots,
};
export default exportedObject;