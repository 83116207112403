import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import {GetWhyChooseUse } from '../../helpers';
import parse from 'html-react-parser';
const WhyChooseUs = () => {

    const [pageContent, setPageContent] = useState('');
    const [pages, setPages] = useState([]);

    const getPageContent = async () => {
        const resp = await GetWhyChooseUse.getWhyChooseContent();
        if (resp && resp.status) {
            setPageContent(resp.whyChooseContent)
        }
    }
    const getWhyChoosePages = async () => {
        const resp = await GetWhyChooseUse.getWhyChoosePages();

        if (resp && resp.status) {
            setPages(resp.dataList)
        }
    }
    useEffect(() => {
        getPageContent();
        getWhyChoosePages();
    }, [])

    return (

        <div className="innerPageWrapper">
            {
                pageContent !== '' && <Helmet>
                    <meta charSet="utf-8" />
                    <title>{pageContent.meta_title}</title>
                    <meta name="description" content={pageContent.meta_description} />
                    <meta name="keywords" content={pageContent.meta_keywords} />
                    <link rel="canonical" href={pageContent.page_url} />
                    {/* og details */}
                    <meta property="og:title" content={pageContent.og_title} />
                    <meta property="og:description" content={pageContent.og_description} />
                    <meta property="og:url" content={'http://destinycalling.in/'} />
                    <meta content="website" property="og:type" />
                    <meta content={pageContent.og_image} property="og:image" />
                    <meta content={pageContent.og_img_alt} property="og:image:alt" />
                    <meta content="Destiny Calling" property="og:site_name" />
                    {/* twitter details */}
                    <meta name="twitter:card" content={pageContent.twitter_card} />
                    <meta name="twitter:site" content={pageContent.twitter_site} />
                    <meta name="twitter:title" content={pageContent.twitter_title} />
                    <meta name="twitter:description" content={pageContent.twitter_description} />
                    <meta name="twitter:image" content={pageContent.twitter_image} />
                </Helmet>
            }
            {
                pageContent !== '' && <>
                    <div className="page-banner" style={{
                        backgroundImage: `url(${pageContent.main_banner})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        minHeight: '500px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px'
                    }}>
                        <div className='patchContainer'>
                            <div className='whyAlzContent'>
                                {parse(pageContent.banner_content)}
                            </div>
                        </div>
                    </div>

                    <div className="page-content pt-50 pb-50">
                        <div className='services'>
                            <div className='container'>
                                <div className='row'>
                                    {
                                        pages.length > 0 && pages.map((item)=>{
                                            return <div className='col-lg-4' key={item.title}>
                                            <div className='service-items'>
                                                <img src={item.thumbnail_image} alt={item.thumbnail_image} />
                                                <h3>{item.sub_title}</h3>
                                                <h4>{item.title}</h4>
                                                 {parse(item.short_description)}
                                                <Link to={`/why-choose-us/${item.slug}`} className='borderButton'>Learn More</Link>
                                            </div>
                                        </div>
                                        })
                                    }
                                    
                                

                                </div>
                            </div>
                        </div>
                        {parse(pageContent.section_one)}
                    </div>
                </>
            }

        </div>
    )
}

export default WhyChooseUs