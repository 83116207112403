import React,{Fragment, useEffect, useState} from 'react'
import { GetPages, GetPosts } from '../../helpers';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns'
import { Helmet } from 'react-helmet-async';
const Blog = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [seoDetail, setSeoDetail] = useState('');
  // const [page, setPage] = useState(1);

   const getData = async ()=>{
    const resp = await GetPosts.getAllPost();
    if(resp && resp.status){
      setData(resp.posts);
      setLoading(false);
    } 
    // setData((prev) => [...prev, ...resp]);
  
   }
   
   const getSeo = async ()=>{
    const resp = await GetPages.getSeo('blog')
    if(resp && resp.status){
      setSeoDetail(resp.seoData)
    } 
   }
   useEffect(()=>{
    getData();
    getSeo();
   },[])
  //  useEffect(()=>{
  //   getData();
  //  },[page])

  //  const handelInfiniteScroll = async () => {
  //   try {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       console.log(data.length);
  //       if(data.length < 50){
  //           setLoading(true);
  //           setPage((prev) => prev + 1);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handelInfiniteScroll);
  //   return () => window.removeEventListener("scroll", handelInfiniteScroll);
  // }, []);

 
  return (
    <Fragment>
  {
                seoDetail && seoDetail !== '' &&   <Helmet>
              <meta charSet="utf-8" />
                <title>{seoDetail.meta_title}</title>
                <meta name="description" content={seoDetail.meta_description}/>
                <meta name="keywords" content={seoDetail.meta_keywords}/>
                <link rel="canonical" href={seoDetail.page_url} />
                 {/* og details */}
                 <meta property="og:title" content={seoDetail.og_title}/>
                <meta property="og:description" content={seoDetail.og_description}/>
                <meta property="og:url" content={'http://destinycalling.in/'}/>
                <meta content="website" property="og:type"/>
                <meta content={seoDetail.og_image} property="og:image" />
                <meta content={seoDetail.og_img_alt} property="og:image:alt"/>
                <meta content="Destiny Calling" property="og:site_name"/>
                {/* twitter details */}
                <meta name="twitter:card" content={seoDetail.twitter_card}/>
                <meta name="twitter:site" content={seoDetail.twitter_site}/>
                <meta name="twitter:title" content={seoDetail.twitter_title}/>
                <meta name="twitter:description" content={seoDetail.twitter_description}/>
                <meta name="twitter:image" content={seoDetail.twitter_image}/>
            </Helmet>
            }
    <div className='innerPageWrapper'>

        <div className='innerPageBanner'>
                <div className='bannerImg'>
                    <img src='/images/blog.jpg' alt='about-banner' className='img-fluid' />
                </div>
        </div>
        <div className='page-content pt-50 pb-50'>
            <div className='container blogList'>
               <h1>Our Blog</h1>
              {
                isLoading ? <div className="fakeLoader" ><div className="lod-1"><div className="lod-2"></div></div></div> :  <div className='row'>
                {
                    data.map((item, index)=>{
                        return   <div className='col-lg-4 col-md-4' key={index}>
                          <Link to={`/blog/${item.slug}`}>
                          <div className='blogItems'>
                            <div className='blogThumb'>
                                <img src={item.thumbnail} alt='blogThumb'/>
                             <div className='author_details'>
                              <h6>{item.auther_name}</h6>
                              <p>{ format (parseISO(item.post_date), 'd MMM Y') }</p>
                            </div>
                            </div>
                          
                            <div className='blogTitle'>
                                <h3>{item.title}</h3>
                            </div>
                        </div>
                          </Link>
                    </div>
                    })
                }
              

              </div>
              }

            </div>
        </div>
    </div>
</Fragment>
  )
}

export default Blog