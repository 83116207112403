import React from 'react'
const ImageGallery = ({images}) => {
  return (
    <div className="snapList">
        {
                    images.map((item)=>{
  return  <div className="spanItem"  key={item.id}>
        <img
            src={item.image_url}
            alt="sp-1"
            className="img-fluid"
        />
    </div>
    })
  }
</div>

  
    
  )
}

export default ImageGallery