import React,{useState} from 'react'
import '../assets/css/accordions.css'
import { FiChevronDown } from "react-icons/fi";
import parse from 'html-react-parser';
function Accordions({data}) {
  const [isActive, setActive] = useState(0);
  const handleActive = (current) =>{
    if(current === isActive){
      setActive(null);
    }else{
      setActive(current);
    }
   
  }
  return (
     <div className='accordionWrapper'>
        {
          data.map((item, i)=>{
            return <div key={i} className={isActive === i ? 'accordionItem active-item' : 'accordionItem'}>
                <div className={isActive === i ? 'accQues active-item' : 'accQues'}>
                   <button onClick={()=>handleActive(i)}>
                   <span>{item.ques}</span> 
                    <FiChevronDown  size={18} color={isActive === i ? '#fff' :'#286989'} className='iconAcc'/>
                    {/* <img src='/images/chevron-down.png' alt='arrow'/> */}
                   </button>
                </div>
                <div className={isActive === i ? 'accAns active-item' : 'accAns'}>
                  
                {parse(item.ans)}
                </div>
            </div>
          })
        }
     </div>
  )
}

export default Accordions