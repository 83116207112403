import React, {Fragment} from 'react'

const OurServices = () => {

 
  return (
    <Fragment>
        
    <div className='innerPageWrapper'>

        <div className='innerPageBanner'>
            {/* <div className='innerDesignEle'>
                 <svg width="478" height="188" viewBox="0 0 478 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-6 4C18.9796 37.3181 95.6736 105.586 202.613 112.114C336.288 120.274 398.061 103.444 473 183" stroke="url(#paint0_linear_58_2348)" strokeOpacity="0.8" strokeWidth="13" />
                            <defs>
                                <linearGradient id="paint0_linear_58_2348" x1="-6" y1="4" x2="388.021" y2="341.623" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#286989" />
                                    <stop offset="0.547917" stopColor="#34647a" />
                                    <stop offset="1" stopColor="#88acbc" />
                                </linearGradient>
                            </defs>
                        </svg>
            </div> */}
            <div className='container'>
                <h1>Our Services</h1>
                <div className='bannerImg'>
                    <img src='/images/otherservicesbnr.jpg' alt='about-banner' className='img-fluid' />
                </div>
            </div>
        </div>
        <div className='page-content pt-50 pb-50'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <a href='/'>
                        <div className='serviceItems'>
                          <img src='/images/addSer1.png' alt='serviceImage' className='img-fluid'/>
                          <div className='serviceOverlay'></div>
                          <div className='serviceItemsContent'>
                          <h3>Mock Interview</h3>
                          </div>
                         
                        </div>
                        </a>
                        
                    </div>
                    <div className='col-lg-6 col-md-6'>
                    <a href='/'>
                        <div className='serviceItems'>
                            <img src='/images/ads.png' alt='serviceImage' className='img-fluid'/>
                            <div className='serviceOverlay'></div>
                            <div className='serviceItemsContent'>
                          <h3>CV Building</h3>
                          </div>
                        </div>
                        </a>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                    <a href='/'>
                        <div className='serviceItems'>
                            <img src='/images/addSer2.png' alt='serviceImage' className='img-fluid'/>
                            <div className='serviceOverlay'></div>
                            <div className='serviceItemsContent'>
                          <h3>Certified Language Courses</h3>
                          <p>(CTA - Enroll Now, more details - external link of our French website / landing page).</p>
                          </div>
                        </div>
                        </a>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                    <a href='/wildfit-health-program'>
                        <div className='serviceItems'>
                            <img src='/images/addSer3.png' alt='serviceImage' className='img-fluid'/>
                            <div className='serviceOverlay'></div>
                            <div className='serviceItemsContent'>
                          <h3>Wildfit Health Program</h3>
                          <p>(CTA - Enroll Now, more details - external link of our Wildfit course website / landing page).</p>
                          </div>
                        </div>
                        </a>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
</Fragment>
  )
}

export default OurServices