import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import { Apis } from "../config";
import MeetOurTeam from "../components/common/MeetOurTeam";
import BrandLogos from "../components/common/BrandLogos";
import parse from 'html-react-parser';
const About = () => {
  const [isLoading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const [aboutContent, setAboutContent] = useState('');
  const getData = async () => {
    try {
      const res = await Promise.all([
        fetch(Apis.GetTeamMember),
        fetch(Apis.GetAbout)
      ]);
      const data = await Promise.all(res.map((r) => r.json()));
      if (data) {
        if (data[0] && data[0].status) {
          setTeamData(data[0].teamMembers);
        }
        if (data[1] && data[1].status) {
          setAboutContent(data[1].aboutContent);
        }
       
        
        setLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Fragment>
      {
              aboutContent !== '' &&   <Helmet>
                <meta charSet="utf-8" />
                <title>{aboutContent.meta_title}</title>
                <meta name="description" content={aboutContent.meta_description}/>
                <meta name="keywords" content={aboutContent.meta_keywords}/>
                <link rel="canonical" href={aboutContent.page_url} />
                 {/* og details */}
                 <meta property="og:title" content={aboutContent.og_title}/>
                <meta property="og:description" content={aboutContent.og_description}/>
                <meta property="og:url" content={'http://destinycalling.in/'}/>
                <meta content="website" property="og:type"/>
                <meta content={aboutContent.og_image} property="og:image" />
                <meta content={aboutContent.og_img_alt} property="og:image:alt"/>
                <meta content="Destiny Calling" property="og:site_name"/>
                {/* twitter details */}
                <meta name="twitter:card" content={aboutContent.twitter_card}/>
                <meta name="twitter:site" content={aboutContent.twitter_site}/>
                <meta name="twitter:title" content={aboutContent.twitter_title}/>
                <meta name="twitter:description" content={aboutContent.twitter_description}/>
                <meta name="twitter:image" content={aboutContent.twitter_image}/>
            </Helmet>
            }
      {isLoading ? (
        <div className="fakeLoader">
          <div className="lod-1">
            <div className="lod-2"></div>
          </div>
        </div>
      ) : (
        <div className="innerPageWrapper">
          <div className="page-banner">
            <div className="container">
              <div className="row align-center">
                <div className="col-md-7">
                  <div className="page-banner-content">
                      {parse(aboutContent.section_one_content)}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="page-banner-img">
                    <img src={aboutContent.section_one_image} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {aboutContent !== null && (
            <div className="innerPageBanner">
              <div className="container">
                <h1>{aboutContent.page_title}</h1>
                <div className="bannerImg">
                  <img
                    src={aboutContent.banner_image}
                    alt="about-banner"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          )} */}
          <div className="page-content pt-50 pb-0">
            <div className="container ">
            {parse(aboutContent.section_two_content)}
              <div className="inner-page-section">
                <div className="row align-center">
                  <div className="col-md-5">
                    <div className="inner-img-box">
                      <img src={aboutContent.section_two_thumb_1} alt="img" />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="inner-content-box">
                    {parse(aboutContent.section_two_content_1)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner-page-section pt-0">
                <div className="row align-center">
                  <div className="col-md-7">
                    <div className="inner-content-box">
                    {parse(aboutContent.section_two_content_2)}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="inner-img-box">
                      <img src={aboutContent.section_two_thumb_2} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <MeetOurTeam teamData={teamData} />
            <BrandLogos title={"HOTELS WE HAVE SUCCESSFULLY PLACED IN"} />
          </div>
          <div
            className="career-section"
            style={{
              backgroundImage: `url('/images/blogThumb.jpg')`,
            }}
          >
            <div className="container">
              <div className="career-inner">
               {parse(aboutContent.section_three)} 
              </div>
            </div>
            <div className="careerOverlay"></div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default About;
