import React, { Fragment, useState, useEffect } from 'react'
import { FiMail, FiMapPin } from "react-icons/fi";
import { Formik } from 'formik';
import { GetEnquires, GetPages } from '../helpers';
import swal from 'sweetalert';
import { Helmet } from 'react-helmet-async';

const ContactUs = () => {
    const [isLoading, setLoading] = useState(false);
    const [seoDetail, setSeoDetail] = useState('');
   
    const getSeo = async ()=>{
        const resp = await GetPages.getSeo('contact')
        if(resp && resp.status){
          setSeoDetail(resp.seoData)
        } 
       }
       useEffect(()=>{
        getSeo();
       },[])

    const handleSubmit = async(values, { setSubmitting, resetForm  })=>{
        setLoading(true);
      
         let resp = await GetEnquires.sendContactEnquiry(values);
         if(resp && resp.status){
            swal({
                title: "Good job!",
                text: resp.message,
                icon: "success",
                button: "OK",
              });
           
            setLoading(false);
            setSubmitting(false);
            resetForm();
         }
    }
    return (
        <Fragment>
                  {
                seoDetail && seoDetail !== '' &&   <Helmet>
                <meta charSet="utf-8" />
                <title>{seoDetail.meta_title}</title>
                <meta name="description" content={seoDetail.meta_description}/>
                <meta name="keywords" content={seoDetail.meta_keywords}/>
                <link rel="canonical" href={seoDetail.page_url} />

                 {/* og details */}
                 <meta property="og:title" content={seoDetail.og_title}/>
                <meta property="og:description" content={seoDetail.og_description}/>
                <meta property="og:url" content={'http://destinycalling.in/'}/>
                <meta content="website" property="og:type"/>
                <meta content={seoDetail.og_image} property="og:image" />
                <meta content={seoDetail.og_img_alt} property="og:image:alt"/>
                <meta content="Destiny Calling" property="og:site_name"/>
                {/* twitter details */}
                <meta name="twitter:card" content={seoDetail.twitter_card}/>
                <meta name="twitter:site" content={seoDetail.twitter_site}/>
                <meta name="twitter:title" content={seoDetail.twitter_title}/>
                <meta name="twitter:description" content={seoDetail.twitter_description}/>
                <meta name="twitter:image" content={seoDetail.twitter_image}/>
            </Helmet>
            }
            <div className='innerPageWrapper'>

                <div className='innerPageBanner'>
                    {/* <div className='innerDesignEle'>
                        <svg width="491" height="196" viewBox="0 0 491 196" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 -49C30.9796 -4.1415 107.674 87.7727 214.613 96.5613C348.288 107.547 410.061 84.8889 485 192" stroke="url(#paint0_linear_58_3809)" strokeOpacity="0.8" strokeWidth="13" />
                            <defs>
                                <linearGradient id="paint0_linear_58_3809" x1="6" y1="-49" x2="492.334" y2="260.517" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#286989" />
                    <stop offset="0.547917" stopColor="#34647a" />
                    <stop offset="1" stopColor="#88acbc" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div> */}
                    
                     
                        <div className='bannerImg'>
                            <img src='/images/contactpagebnr.jpg' alt='about-banner' className='img-fluid' />
                        </div>
                    
                </div>
                <div className='page-content pt-50 pb-50'>
                    <div className='container'>
                        <div className='contactBox'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='contactItem'>
                                        <div className='contactIcon'>
                                            <FiMail size={35} color={`#34647a`} />
                                        </div>
                                        <div className='contactEmails'>
                                            <p><a href='mailto:interns@destinycalling.in'>interns@destinycalling.in</a></p>
                                            {/* <p><a href='/'>priyanka@alzea.org</a></p> */}
                                        </div>

                                    </div>

                                    <div className='contactItem mb-0'>
                                        <div className='contactIcon'>
                                            <FiMapPin size={35} color={`#34647a`} />
                                        </div>
                                        <div className='contactEmails'>
                                            {/* <p><a href='/'>Neelkanth Palms, Classique c,<br />
                                                901, behind Cinemax,<br />
                                                Ghodbunder road, Thane, Maharashtra - 400610 </a></p> */}
                                                <p><a href='/'> Office 2104, Solus, next to Rodas Enclave,<br /> Hiranandani Estate, Thane, Maharashtra 400607</a></p>

                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-6 positionRelative'>
                                    <div className='contactForm'>
                                        <h3>Connect With Us</h3>
                                        <Formik
                                            initialValues={{ name:'', email: '', phone: '', country:'', course_enrolled:'' }}
                                            validate={values => {
                                                const errors = {};
                                                if (!values.name) {
                                                    errors.name = 'Name is required';
                                                }
                                               
                                                if (!values.email) {
                                                    errors.email = 'Email is required';
                                                } else if (
                                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                                ) {
                                                    errors.email = 'Invalid email address';
                                                }

                                                if (!values.phone) {
                                                    errors.phone = 'Phone is required';
                                                }else if(!/^[0-9]{10}$/i.test(values.phone)){
                                                    errors.phone = 'Invalid phone number';
                                                }
                                                if (!values.country) {
                                                    errors.country = 'Country is required';
                                                }
                                                if (!values.course_enrolled) {
                                                    errors.course_enrolled = 'Course enrolled is required';
                                                }
                                                return errors;
                                            }}
                                            onSubmit={handleSubmit}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting,
                                                /* and other goodies */
                                            }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className='form-group'>
                                                        <label htmlFor='name'>Name</label>
                                                        <input type='text' 
                                                        id="name" 
                                                        className="form-control" 
                                                        name="name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                        />
                                                         {errors.name && touched.name && <span className='error-help'>{errors.name}</span>}
                                                    </div>
                                                    <div className='form-group'>
                                                        <label htmlFor='email'>Email</label>
                                                        <input type='text'
                                                        id="email"
                                                        className="form-control"
                                                        name="email"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                        />
                                                         {errors.email && touched.email && <span className='error-help'>{errors.email}</span>}
                                                    </div>
                                                    <div className='form-group'>
                                                        <label htmlFor='phone'>Mobile Number</label>
                                                        <input type='text'
                                                        id="phone"
                                                        className="form-control"
                                                        name="phone"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.phone}
                                                        />
                                                         {errors.phone && touched.phone && <span className='error-help'>{errors.phone}</span>}
                                                    </div>
                                                    <div className='form-group'>
                                                        <label htmlFor='country'>Interested country</label>
                                                        <input type='text'
                                                        id="country"
                                                        className="form-control"
                                                        name="country"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.country}
                                                        />
                                                         {errors.country && touched.country && <span className='error-help'>{errors.country}</span>}
                                                    </div>
                                                    <div className='form-group'>
                                                        <label htmlFor='course_enrolled'>Course enrolled</label>
                                                        <select className="form-control" name="course_enrolled" onChange={handleChange} onBlur={handleBlur} value={values.interested_internship_destination} id="course_enrolled">
                             <option value={''}>---Select---</option>
                             <option value={'Certificate'}>Certificate</option>
                             <option value={'Degree'}>Degree</option>
                             <option value={'Diploma'}>Diploma</option>
                          </select>
                                                         {errors.course_enrolled && touched.course_enrolled && <span className='error-help'>{errors.course_enrolled}</span>}
                                                    </div>
                                                    
                                                   
                                                        <button className='sbtContact' type="submit" disabled={isSubmitting}>
                                                            {
                                                                isLoading ? 'Please wait..' : 'Send Message'
                                                            }
                                                            
                                                            </button>
                                                   
                                                </form>
                                            )}
                                        </Formik>
                                        {/* <div className='seperator'>Or</div>
                                        <div className='mobileShowCase'>
                                            <FiPhoneCall size={25} />
                                            <p>+91 95611 01102</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='googleMap'>
                           

                            <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.470862985918!2d72.98232927368048!3d19.261879246113875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bbe0bf194101%3A0x24f9d95a66c13352!2sSolus%20building!5e0!3m2!1sen!2sin!4v1707136755901!5m2!1sen!2sin"
  width={'100%'}
  height={350}
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  title="map"
  referrerPolicy="no-referrer-when-downgrade"
/>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ContactUs