import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import ImageGallery from "../../components/common/ImageGallery";
import ProgramAccordions from "../../components/ProgramAccordions";
import { Apis } from "../../config";
import Brochures from "../../components/models/Brochures";
import { GetPrograms } from "../../helpers";
import { Helmet } from 'react-helmet-async';
const SingleProgram = () => {
  const { slug } = useParams();
  const [currentItem, setCurrentItem] = useState('');
  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);

  const [program, setProgram] = useState('');
  const [inclusions, setInclusions] = useState([]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleChangeId = (id) => {
    setCurrentItem(inclusions.find(el => el.id === id));
  }

  const getData = async () => {
    try {
      const res = await Promise.all([
        fetch(Apis.GetAllTestimonial)
      ]);
      const data = await Promise.all(res.map((r) => r.json()));
      if (data) {
        if (data[0] && data[0].status) {
          setTestimonials(data[0].testimonials);
        }
        setLoading(false)
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };





  const getProgram = async (page_url) => {
    const resp = await GetPrograms.getSingleProgram(page_url);
    if (resp && resp.status) {
      setProgram(resp.program)
      setInclusions(resp.program.inclusions);
      let incData = resp.program.inclusions && resp.program.inclusions.length > 0 ? resp.program.inclusions[0] : '';
      setCurrentItem(incData);
    }
  }
  useEffect(() => {
    getProgram(slug);
  }, [slug])

  useState(() => {
    getData();
  }, [])

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <Fragment>
      {
         program !== '' && <Helmet>
          <meta charSet="utf-8" />
          <title>{program.meta_title}</title>
          <meta name="description" content={program.meta_description} />
          <meta name="keywords" content={program.meta_keywords} />
          <link rel="canonical" href={program.page_url} />
          {/* og details */}
          <meta property="og:title" content={program.og_title} />
          <meta property="og:description" content={program.og_description} />
          <meta property="og:url" content={'http://destinycalling.in/'} />
          <meta content="website" property="og:type" />
          <meta content={program.og_image} property="og:image" />
          <meta content={program.og_img_alt} property="og:image:alt" />
          <meta content="Destiny Calling" property="og:site_name" />
          {/* twitter details */}
          <meta name="twitter:card" content={program.twitter_card} />
          <meta name="twitter:site" content={program.twitter_site} />
          <meta name="twitter:title" content={program.twitter_title} />
          <meta name="twitter:description" content={program.twitter_description} />
          <meta name="twitter:image" content={program.twitter_image} />
        </Helmet>
      }
      {
         program !== '' &&  <div className="innerPageWrapper">
        
        
         <div className="innerPageBanner-2">
           <img
             src={program.main_banner}
             alt="about-banner"
             className="img-fluid"
           />
         </div>
         <div className="page-content pt-15 pb-50">
           <section className="inner-program-sec-1">
             <div className="container">
               {
                 parse(program.page_content)
               }
               {
                 program.openings && program.openings.length > 0 && <>
                   <div className='eligibility'>
                     <h4 className='lang-title'>Current Openings by Department</h4>
                     <div className='eligibilityItems'>
                       <ul>
                         {
                           program.openings.map((item, i) => {
                             return <li key={item.id}><img src={item.opening_icon} alt='icon' /><span>{item.opening_title}</span></li>
                           })
                         }


                       </ul>
                     </div>
                   </div>
                   <div className="application-btn">
                     <Link to={"/contact-us"} className="primaryButton">START MY APPLICATION</Link>
                   </div>
                 </>
               }
             </div>

           </section>
           <section className="inner-program-sec-2">
             <div className="container">
               <h4 className="lang-title text-center">
                {program.testimonial_title}
               </h4>
               <Carousel responsive={responsive} renderArrowsWhenDisabled={true}>
                 {
                   !isLoading && testimonials && testimonials.length > 0 && testimonials.map((item, i) => {
                     return <div className="cl-items" key={item}>
                       <div className="message-box">
                         {
                           parse(item.description)
                         }
                       </div>

                       <div className="author-details">
                         <span>{item.name}</span>
                         <span>{item.designation}</span>
                       </div>
                     </div>
                   })
                 }


               </Carousel>
             </div>
           </section>
           {
             inclusions.length > 0 && <section className="inner-program-sec-3">
               <div className="container">
                 <h4 className="lang-title text-center">
                 {program.tab_title}
                 </h4>
                 <div className="dxt-box">
                   <div className="int-tab">
                     <ul className="int-tab-link">
                       {
                         inclusions.map((item, i) => {
                           return <li key={item.id}>
                             <button className={currentItem !== '' && currentItem.id === item.id ? "active" : ""} onClick={() => handleChangeId(item.id)}>{item.inclusions_title}</button>
                           </li>
                         })
                       }
                     </ul>
                     <div className="int-tab-body">
                       {
                         currentItem !== '' && <>
                           {parse(currentItem.long_description)}
                         </>
                       }
                     </div>
                   </div>
                 </div>

                 <div className="mb-box">
                   <ProgramAccordions data={inclusions} />
                 </div>
               </div>
             </section>
           }

           {
             program.snapshots && program.snapshots.length > 0 && <section className="inner-program-sec-4">
               <div className="container">
                 <h4 className="lang-title text-center">
                 {program.snapshot_title}
                 </h4>

                 <ImageGallery images={program.snapshots} />
               </div>
             </section>
           }

           <section className="inner-program-sec-5">
             <div className="container">
               <div className="row">
                 <div className="col-md-4">
                   <div className="iconBox">
                     <div className="iconBoxImage">
                       <img
                         src="/images/dream.png"
                         alt="sp-1"
                         className="img-fluid"
                       />
                     </div>
                     <div className="iconBoxContent">
                       <h4>Get our brochures and a quote</h4>
                       <button className="primaryButton " onClick={() => setOpen(true)}>
                         DOWNLOAD NOW
                       </button>
                     </div>
                   </div>
                 </div>
                 <div className="col-md-4">
                   <div className="iconBox">
                     <div className="iconBoxImage">
                       <img
                         src="/images/ques.png"
                         alt="sp-1"
                         className="img-fluid"
                       />
                     </div>
                     <div className="iconBoxContent">
                       <h4>Why choose destiny calling?</h4>
                       <Link to={"/why-choose-us"} className="primaryButton">
                         WHY US
                       </Link>
                     </div>
                   </div>
                 </div>
                 <div className="col-md-4">
                   <div className="iconBox">
                     <div className="iconBoxImage">
                       <img
                         src="/images/connect.png"
                         alt="sp-1"
                         className="img-fluid"
                       />
                     </div>
                     <div className="iconBoxContent">
                       <h4>Book a free discovery call with our advisor today</h4>
                       <Link to={"/contact-us"} className="primaryButton">
                         CONTACT US
                       </Link>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>

         </div>
         <section
           className="career-section"
           style={{
             backgroundImage: `url('/images/blogThumb.jpg')`,
           }}
         >
           <div className="container">
             <div className="career-inner-2">
               <h3>HAVE QUESTIONS? SCROLL THROUGH OUR EFFECTIVE FAQ RESOURCE THAT WILL EDUCATE, INFORM AND ANSWER ALL YOUR QUERIES.</h3>
               <Link to="/faq" className="borderButton">
                 FAQ'S
               </Link>
             </div>
           </div>
           <div className="careerOverlay"></div>
         </section>
         <Brochures isOpen={isOpen} handleClose={handleClose} brochureUrl={program.brochure_link}/>
      
     

   </div>
      }
    
     
    </Fragment>
  );
};

export default SingleProgram;
