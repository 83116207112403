import React, { Component, Fragment } from "react";
import { Helmet } from 'react-helmet-async';
import { MdOutlineClose } from "react-icons/md";
import Modal from "../components/models/Modal";
import { Apis } from "../config";
import parse from "html-react-parser";
import { FiFacebook, FiTwitter, FiInstagram, FiShare2 } from "react-icons/fi";

export default class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLoading: true,
      testimonials: [],
      modalData: null,
      videoTestimonials: [],
      videoModalData: null,
      activeSocialID: null,
      seoDetail:''
    };
  }
  openModal = (slId) => {
    this.setState({
      isLoading: true,
      isOpen: true,
      isOpenVideo: false,
    });
    const selectedData = this.state.testimonials.find((el) => el.id === slId);
    this.setState({
      modalData: selectedData,
      isLoading: false,
    });
  };
  closeModal = () => {
    this.setState({
      isOpen: false,
      modalData: null,
    });
  };

  openVideoModal = (slId) => {
    let selectedVideo = this.state.videoTestimonials.find(
      (el) => el.id === slId
    );
    this.setState({
      videoModalData: selectedVideo,
      isOpenVideo: true,
    });
  };
  closeVideoModal = () => {
    this.setState({
      isOpenVideo: false,
    });
  };

  getData = async () => {
    try {

      const res = await Promise.all([
        fetch(Apis.GetAllTestimonial),
        fetch(Apis.GetAllVideoTestimonial),
        fetch(Apis.GetSeo+'testimonial'),
      ]);
      const data = await Promise.all(res.map((r) => r.json()));
      if (data) {
        if (data[0] && data[0].status) {
          this.setState({
            testimonials: data[0].testimonials,
          });
        }
        if (data[1] && data[1].status) {
          this.setState({
            videoTestimonials: data[1].videoTestimonials,
          });
        }
        if (data[2] && data[2].status) {
          this.setState({
            seoDetail: data[2].seoData,
          });
        }
        this.setState({
          isLoading: false,
        });
      }

    } catch (error) {
      throw Error("Promise failed");
    }
  };

  componentDidMount() {
    this.getData();
  }
  toggleSocial = (id) => {
    if (this.state.activeSocialID === id) {
      this.setState({
        activeSocialID: null,
      });
    } else {
      this.setState({
        activeSocialID: id,
      });
    }
  };

  render() {
    const {
      isOpen,
      isLoading,
      modalData,
      // isOpenVideo,
      testimonials,
      // videoTestimonials,
      // videoModalData,
      activeSocialID,
      seoDetail
    } = this.state;

    return (
      <Fragment>
         {
                seoDetail && seoDetail !== '' &&   <Helmet>
                <meta charSet="utf-8" />
                <title>{seoDetail.meta_title}</title>
                <meta name="description" content={seoDetail.meta_description}/>
                <meta name="keywords" content={seoDetail.meta_keywords}/>
                <link rel="canonical" href={seoDetail.page_url} />
                 {/* og details */}
                 <meta property="og:title" content={seoDetail.og_title}/>
                <meta property="og:description" content={seoDetail.og_description}/>
                <meta property="og:url" content={'http://destinycalling.in/'}/>
                <meta content="website" property="og:type"/>
                <meta content={seoDetail.og_image} property="og:image" />
                <meta content={seoDetail.og_img_alt} property="og:image:alt"/>
                <meta content="Destiny Calling" property="og:site_name"/>
                {/* twitter details */}
                <meta name="twitter:card" content={seoDetail.twitter_card}/>
                <meta name="twitter:site" content={seoDetail.twitter_site}/>
                <meta name="twitter:title" content={seoDetail.twitter_title}/>
                <meta name="twitter:description" content={seoDetail.twitter_description}/>
                <meta name="twitter:image" content={seoDetail.twitter_image}/>
            </Helmet>
            }
        <div className="innerPageWrapper">
          <div className="innerPageBanner">

   
              <div className="bannerImg">
                <img
                  src="/images/testi.jpg"
                  alt="about-banner"
                  className="img-fluid"
                />
              </div>
          </div>
          <div className="page-content overH pt-50 pb-50">
            <div className="testimonialList">
              <div className="container">
              <h1>WHAT OUR STUDENTS HAVE TO SAY ABOUT US!</h1>
              <div className="row">
              {testimonials.length !== 0 &&
                    testimonials.map((item, index) => {
                      return (
                        <div className="col-md-6 mb-15e" key={index}>
                        <div className="testimonialItem ">
                          <div className="aboutUser">
                            <div className="userBox">
                              <div className="userThumb">
                                <img src={item.profile_pic} alt="user" />
                              </div>
                              <div className="userDetails">
                                <h4>{item.name}</h4>
                                <p>{item.designation}</p>
                              </div>
                            </div>
                            {/* <div className="userSocialsBox">
                              <button
                                onClick={() => this.toggleSocial(item.id)}
                              >
                                <FiShare2 fontSize={18} color="#286989" />
                              </button>
                              <ul
                                className={`userSocials ${
                                  activeSocialID === item.id ? "show" : ""
                                }`}
                              >
                                <li>
                                  <a href={item.facebook} target="_blank" rel="noreferrer">
                                    <FiFacebook fontSize={18} color="#286989" />
                                  </a>
                                </li>
                                <li>
                                  <a href={item.twitter} target="_blank" rel="noreferrer">
                                    <FiTwitter fontSize={18} color="#286989" />
                                  </a>
                                </li>
                                <li>
                                  <a href={item.instagram} target="_blank" rel="noreferrer">
                                    <FiInstagram
                                      fontSize={18}
                                      color="#286989"
                                    />
                                  </a>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                          <div className="userMessage">
                            {item.description.length > 500 ? (
                              <>
                                <div className="userWords">
                                  {parse(item.description)}
                                </div>
                                <button
                                  onClick={() => this.openModal(item.id)}
                                  className="rdm"
                                >
                                  read more
                                </button>
                              </>
                            ) : (
                              <>{parse(item.description)}</>
                            )}
                          </div>
                        </div>
                        </div>
                     
                      );
                    })}
                    </div>
              
              </div>
              <Modal isOpen={isOpen} closeModal={this.closeModal}>
                {isLoading ? (
                  <p>Please wait..</p>
                ) : (
                  <>
                    {modalData !== null && (
                      <>
                        <div className="modalHeader">
                          <div className="userInfo">
                            <div className="userIcon">
                              <img src={modalData.profile_pic} alt="user" />
                            </div>
                            <div className="userDeg">
                              <h1>{modalData.name}</h1>
                              <p>{modalData.designation}</p>
                            </div>
                          </div>
                          <button onClick={this.closeModal}>
                            <MdOutlineClose size={25} />
                          </button>
                        </div>
                        <div className="modalContent">
                          {parse(modalData.description)}
                        </div>
                      </>
                    )}
                  </>
                )}
              </Modal>
              <div className="designEle5">
                <svg
                  width="317"
                  height="418"
                  viewBox="0 0 317 418"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.22314 1C11.0514 50.4177 41.8572 162.79 134.455 216.939C250.202 284.624 315.225 292.08 338.132 416.741"
                    stroke="url(#paint0_linear_224_19)"
                    strokeOpacity="0.8"
                    strokeWidth="13"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_224_19"
                      x1="7.22314"
                      y1="1"
                      x2="295.494"
                      y2="490.996"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#286989" />
                      <stop offset="0.547917" stopColor="#34647a" />
                      <stop offset="1" stopColor="#88acbc" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            {/* <div className="videoTestimonial">
              <div className="container">
                <h1>Video Testmonials</h1>
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  ssr={true}
                  itemClass="carousel-item-mr-40-px"
                  removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                >
                  {videoTestimonials.map((item, index) => {
                    return (
                      <div className="videoTestimonialItem">
                        <div className="videoThumb">
                          <img src={item.cover_image} alt="videoThumb" />
                        </div>
                        <button
                          className="playBtn"
                          onClick={() => this.openVideoModal(item.id)}
                        >
                          <FaPlay size={50} color="rgba(255, 223, 233, 0.5)" />
                        </button>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div> */}
          </div>
        </div>
        {/* <Modal isOpen={isOpenVideo} closeModal={this.closeVideoModal}>
          <div className="modalHeader">
            <div className="userInfo">
              <div className="userIcon">
                <img src={videoModalData?.profile_pic} alt="user" />
              </div>
              <div className="userDeg">
                <h1>{videoModalData?.name}</h1>
                <p>{videoModalData?.designation}</p>
              </div>
            </div>
            <div className="userSocialsOuter">
              <div className="userSocialsBox">
               
                <ul
                  className={`userSocials show mr-0`}
                >
                  <li>
                    <a href={videoModalData?.facebook} target="_blank" rel="noreferrer">
                      <FiFacebook fontSize={18} color="#286989" />
                    </a>
                  </li>
                  <li>
                    <a href={videoModalData?.twitter} target="_blank" rel="noreferrer">
                      <FiTwitter fontSize={18} color="#286989" />
                    </a>
                  </li>
                  <li>
                    <a href={videoModalData?.instagram} target="_blank" rel="noreferrer">
                      <FiInstagram fontSize={18} color="#286989" />
                    </a>
                  </li>
                </ul>
              </div>
              <button onClick={this.closeVideoModal}>
                <MdOutlineClose size={25} />
              </button>
            </div>
          </div>
          {videoModalData !== null &&
          parseInt(videoModalData.video_type) === 1 ? (
            <div>{parse(videoModalData.video_url)}</div>
          ) : (
            <div>
              <video width={`100%`} height={400} controls autoPlay>
                <source src={videoModalData?.video_url} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </Modal> */}
      </Fragment>
    );
  }
}
